import { defineComponent as _defineComponent } from 'vue';
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Fragment as _Fragment, resolveComponent as _resolveComponent } from "vue";
const _hoisted_1 = {
    key: 1,
    class: "mt-4 flex items-center justify-center"
};
const _hoisted_2 = { class: "flex flex-wrap items justify-center gap-4 py-4" };
const _hoisted_3 = { class: "flex flex-col gap-1" };
const _hoisted_4 = { class: "flex items-center space-x-1" };
const _hoisted_5 = ["title"];
const _hoisted_6 = { class: "flex items-center space-x-1" };
const _hoisted_7 = ["title"];
import { ref } from 'vue';
import jsPDF from 'jspdf';
import { duration, formatter, safeCopy, dataURLtoFile } from '@/common/util';
import { useCertificadosUsuariosStore } from '@/stores';
import UiButton from '@/components/ui/button.vue';
import UiDropdown from '@/components/ui/dropdown.vue';
import UiRadioButton from '@/components/ui/radioButton.vue';
import UiIcon from '@/components/ui/icon.vue';
import PrevCanvas from '@/views/certificado/components/canvas.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PePrevCertificate',
    props: {
        headerTitle: {},
        certificateId: {},
        userId: {},
        type: {},
        idCourseFormation: {},
        course: {},
        trail: {}
    },
    setup(__props, { expose: __expose }) {
        const certificadosUsuariosStore = useCertificadosUsuariosStore();
        const { plantar } = window.configuration || { plantar: {} };
        const defaultPrevCertificate = {
            tags: {},
            json: {},
            courseItems: [],
            trailItems: []
        };
        const prevCertificate = ref({ ...safeCopy(defaultPrevCertificate) });
        const canvasRef = ref();
        const isLoading = ref(true);
        const busy = ref(false);
        const rendered = ref(false);
        const bunnyStatus = ref('initial');
        const viewing = ref('front');
        const certificateUser = ref();
        const props = __props;
        const modal = ref();
        const open = async (data) => {
            if (!data)
                return;
            rendered.value = false;
            isLoading.value = true;
            viewing.value = 'front';
            prevCertificate.value.json = typeof data.jsonCertificado === 'string' ? JSON.parse(data.jsonCertificado) : data.jsonCertificado;
            prevCertificate.value.tags = {
                name: data?.nomeUsuario,
                cpf: formatter.cpfCnpj(data.cpf),
                title: data?.title || null,
                date: data?.dataEmissao
                    ? new Date(data.dataEmissao).toLocaleDateString('pt-BR', { year: 'numeric', month: 'long', day: 'numeric' })
                    : null,
                time: data?.cargaHoraria ? duration(data.cargaHoraria) : null,
                cargo: data?.cargo
            };
            certificateUser.value = {
                title: data?.title || null,
                issueDate: new Date(data.dataEmissao)
            };
            prevCertificate.value.courseItems = data.course || [];
            prevCertificate.value.trailItems = data.trail || [];
            await modal.value?.open();
            if (!canvasRef.value) {
                isLoading.value = false;
                return;
            }
        };
        const saveImage = (page) => {
            const dataURL = canvasRef.value?.getElementsByTagName('canvas')[page === 'front' ? 0 : 1].toDataURL();
            var link = document.createElement('a');
            link.download = 'certificado.png';
            link.href = dataURL;
            link.click();
        };
        const renderComplete = () => {
            rendered.value = true;
        };
        const prepareForLinkedin = () => {
            bunnyStatus.value = 'loading';
            const dataURL = canvasRef.value?.getElementsByTagName('canvas')[0].toDataURL();
            const file = dataURLtoFile(dataURL, 'certificado.png');
            return certificadosUsuariosStore.create(props.certificateId, props.userId, props.type, props.idCourseFormation, file).then((res) => {
                certificateUser.value.credential = res.credencial;
                bunnyStatus.value = 'success';
                return true;
            }).catch(() => {
                bunnyStatus.value = 'error';
            }).finally(() => {
                isLoading.value = false;
            });
        };
        const share = () => {
            busy.value = true;
            prepareForLinkedin().then((result) => {
                if (result) {
                    const linkedInUrlBase = window.configuration.linkedIn.host;
                    const plantarUrlBase = [plantar.host, plantar.pathV1].join('/');
                    const url = `${linkedInUrlBase}/shareArticle/?mini=true&url=${plantarUrlBase}/certificadousuario/${certificateUser.value.credential}/public`;
                    window.open(url, '_blank').focus();
                }
            }).finally(() => {
                busy.value = false;
            });
        };
        const addToLinkedin = () => {
            busy.value = true;
            prepareForLinkedin().then((result) => {
                if (result) {
                    const issueMonth = certificateUser.value.issueDate.getMonth() + 1;
                    const issueYear = certificateUser.value.issueDate.getFullYear();
                    const linkedInUrlBase = window.configuration.linkedIn.host;
                    const plantarUrlBase = [plantar.host, plantar.pathV1].join('/');
                    const certificateUrl = `${plantarUrlBase}/certificadousuario/${certificateUser.value.credential}/public`;
                    const url = `${linkedInUrlBase}/profile/add?startTask=${certificateUser.value.title}&name=${certificateUser.value.title}&issueYear=${issueYear}&issueMonth=${issueMonth}&certUrl=${certificateUrl}&certId=${certificateUser.value.credential}`;
                    window.open(url, '_blank').focus();
                }
            }).finally(() => {
                busy.value = false;
            });
        };
        const savePdf = () => {
            const pdf = new jsPDF('l', 'mm', 'a4');
            const imgs = [
                new Promise((resolve) => {
                    const img = new Image();
                    img.src = canvasRef.value?.getElementsByTagName('canvas')[0].toDataURL();
                    img.onload = () => {
                        pdf.addImage(img, 'PNG', 0, 0, 297, 210);
                        resolve();
                    };
                }),
                new Promise((resolve) => {
                    if (!prevCertificate.value.json?.settings?.page?.backSide)
                        return resolve();
                    const img = new Image();
                    img.src = canvasRef.value?.getElementsByTagName('canvas')[1].toDataURL();
                    img.onload = () => {
                        pdf.addPage();
                        pdf.addImage(img, 'PNG', 0, 0, 297, 210);
                        resolve();
                    };
                })
            ];
            Promise.all(imgs).then(() => pdf.save('certificado.pdf'));
        };
        __expose({
            open
        });
        return (_ctx, _cache) => {
            const _component_pe_modal = _resolveComponent("pe-modal");
            const _directive_tooltip = _resolveDirective("tooltip");
            return (_openBlock(), _createBlock(_component_pe_modal, {
                ref_key: "modal",
                ref: modal,
                header: _ctx.headerTitle || _ctx.$t('modals.previewCertificate'),
                width: "960px",
                "close-on-esc": ""
            }, {
                footer: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                        (rendered.value)
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                _createVNode(UiDropdown, null, {
                                    content: _withCtx(() => [
                                        _createElementVNode("div", _hoisted_3, [
                                            _createVNode(UiButton, {
                                                type: "ghost",
                                                icon: "photo",
                                                class: "p-2",
                                                onClick: _cache[3] || (_cache[3] = () => saveImage('front'))
                                            }, {
                                                default: _withCtx(() => _cache[8] || (_cache[8] = [
                                                    _createTextVNode("Como Imagem (Frente)")
                                                ])),
                                                _: 1
                                            }),
                                            (prevCertificate.value.json?.settings?.page?.backSide)
                                                ? (_openBlock(), _createBlock(UiButton, {
                                                    key: 0,
                                                    type: "ghost",
                                                    icon: "photo",
                                                    class: "p-2",
                                                    onClick: _cache[4] || (_cache[4] = () => saveImage('back'))
                                                }, {
                                                    default: _withCtx(() => _cache[9] || (_cache[9] = [
                                                        _createTextVNode("Como Imagem (Verso)")
                                                    ])),
                                                    _: 1
                                                }))
                                                : _createCommentVNode("", true),
                                            _createVNode(UiButton, {
                                                type: "ghost",
                                                icon: "pdf",
                                                class: "p-2",
                                                onClick: _cache[5] || (_cache[5] = () => savePdf())
                                            }, {
                                                default: _withCtx(() => _cache[10] || (_cache[10] = [
                                                    _createTextVNode("Como PDF")
                                                ])),
                                                _: 1
                                            })
                                        ])
                                    ]),
                                    default: _withCtx(() => [
                                        _createVNode(UiButton, {
                                            type: "primary",
                                            class: "p-2 uppercase"
                                        }, {
                                            default: _withCtx(() => [
                                                _createVNode(UiIcon, { icon: "download" }),
                                                _createTextVNode(" " + _toDisplayString(_ctx.$t('button.download')), 1)
                                            ]),
                                            _: 1
                                        })
                                    ]),
                                    _: 1
                                }),
                                _createElementVNode("div", _hoisted_4, [
                                    _createVNode(UiButton, {
                                        type: "primary",
                                        outline: "",
                                        icon: "brand-linkedin",
                                        text: _ctx.$t('labels.shareLinkedin'),
                                        class: "p-2 uppercase",
                                        loading: busy.value,
                                        onClick: _cache[6] || (_cache[6] = () => share())
                                    }, null, 8, ["text", "loading"]),
                                    _withDirectives((_openBlock(), _createElementBlock("div", {
                                        title: _ctx.$t('tooltips.certificates.linkedin'),
                                        class: "flex items-center space-x-2 cursor-help"
                                    }, [
                                        _createVNode(UiIcon, {
                                            icon: "info-circle",
                                            size: "22px",
                                            class: "text-[--c-primary-700] dark:text[--c-primary-200]"
                                        })
                                    ], 8, _hoisted_5)), [
                                        [_directive_tooltip]
                                    ])
                                ]),
                                _createElementVNode("div", _hoisted_6, [
                                    _createVNode(UiButton, {
                                        type: "primary",
                                        outline: "",
                                        icon: "brand-linkedin",
                                        text: _ctx.$t('labels.linkLinkedin'),
                                        class: "p-2 uppercase",
                                        loading: busy.value,
                                        onClick: _cache[7] || (_cache[7] = () => addToLinkedin())
                                    }, null, 8, ["text", "loading"]),
                                    _withDirectives((_openBlock(), _createElementBlock("div", {
                                        title: _ctx.$t('tooltips.certificates.profile'),
                                        class: "flex items-center space-x-2 cursor-help"
                                    }, [
                                        _createVNode(UiIcon, {
                                            icon: "info-circle",
                                            size: "22px",
                                            class: "text-[--c-primary-700] dark:text[--c-primary-200]"
                                        })
                                    ], 8, _hoisted_7)), [
                                        [_directive_tooltip]
                                    ])
                                ])
                            ], 64))
                            : _createCommentVNode("", true)
                    ])
                ]),
                default: _withCtx(() => [
                    _createElementVNode("div", {
                        class: "py-4",
                        ref_key: "canvasRef",
                        ref: canvasRef
                    }, [
                        (modal.value?.isOpen)
                            ? (_openBlock(), _createBlock(PrevCanvas, {
                                key: 0,
                                modelValue: prevCertificate.value.json,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((prevCertificate.value.json) = $event)),
                                tags: prevCertificate.value.tags,
                                viewing: viewing.value,
                                courseItems: prevCertificate.value.courseItems,
                                trailItems: prevCertificate.value.trailItems,
                                preview: "",
                                onRenderComplete: _cache[1] || (_cache[1] = () => renderComplete())
                            }, null, 8, ["modelValue", "tags", "viewing", "courseItems", "trailItems"]))
                            : _createCommentVNode("", true),
                        (prevCertificate.value.json?.settings?.page?.backSide)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                                _createVNode(UiRadioButton, {
                                    modelValue: viewing.value,
                                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((viewing).value = $event)),
                                    options: [
                                        { value: 'front', label: _ctx.$t('Frente') },
                                        { value: 'back', label: _ctx.$t('Verso') },
                                        { value: 'both', label: _ctx.$t('Ambos') }
                                    ],
                                    hideRadio: ""
                                }, null, 8, ["modelValue", "options"])
                            ]))
                            : _createCommentVNode("", true)
                    ], 512)
                ]),
                _: 1
            }, 8, ["header"]));
        };
    }
});
